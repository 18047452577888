import { motion } from 'framer-motion';

const FadeInAnimation = ({ children }) => {
    return (
        <motion.div
            whileInView={{ opacity: [0, 0, 1] }}
            transition={{ duration: 0.5 }}
        >
            {children}
        </motion.div>
    );
};

export default FadeInAnimation;