import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { LazyImage } from "../"

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const checkScrollTopVisibility = () => {
      if (window.pageYOffset > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", checkScrollTopVisibility);
    return () => window.removeEventListener("scroll", checkScrollTopVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <motion.button
      className={`scroll-to-top-button ${isVisible ? "visible" : ""}`}
      onClick={scrollToTop}
      whileTap={{ scale: 0.9 }}
    >
      <LazyImage src="/arrow-up.webp" alt="arrow-up" width="100%" />
    </motion.button>
  );
};

export default ScrollToTopButton;
