import { motion } from "framer-motion";
import PropTypes from "prop-types";

const TextAppearAnimation = ({ text, className, colorChangeIndex, center }) => {
    const words = text.split(" ");

    const container = {
        hidden: { opacity: 0 },
        visible: (i = 1) => ({
            opacity: 1,
            transition: { staggerChildren: 0.03, delayChildren: 0.04 * i },
        }),
    };

    const child = {
        visible: {
            opacity: 1,
            x: 0,
            y: 0,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 100,
            },
        },
        hidden: {
            opacity: 0,
            x: -20,
            y: 10,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 100,
            },
        },
    };

    return (
        <motion.div
            style={{ overflow: "hidden", display: "flex", flexWrap: "wrap", justifyContent: center ? "center" : "flex-start" }}
            className={className}
            variants={container}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }}
        >
            {words.map((word, index) => (
                <motion.span
                    variants={child}
                    style={{ marginRight: "6px", fontFamily: "inherit" }}
                    key={index}
                    className={index >= colorChangeIndex ? "textColorPrimary fw-bold" : ""}
                >
                    {word}
                </motion.span>
            ))}
        </motion.div>
    );
};

export default TextAppearAnimation;

TextAppearAnimation.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    colorChangeIndex: PropTypes.number,
    center: PropTypes.bool,
};