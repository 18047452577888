import styles from './Home.module.css'
import { LazyImage } from '../../components'
import { Row, Col } from 'react-bootstrap'
import { AiFillCheckCircle } from 'react-icons/ai'
import Form from 'react-bootstrap/Form';
import { useRef, useState, useEffect } from 'react';
import swal from 'sweetalert';
import { MotionDiv, FadeInAnimation, WordAnimation, TextAppearAnimation } from '../../components'
import { motion } from 'framer-motion';

const Home = () => {
  const section2Ref = useRef(null);
  const section6Ref = useRef(null);
  const [email, setEmail] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    window.Email.send({
      Host: "smtp.elasticemail.com",
      Username: process.env.REACT_APP_USER_NAME,
      Password: process.env.REACT_APP_PASSWORD,
      To: process.env.REACT_APP_TO_EMAIL,
      From: process.env.REACT_APP_FROM_EMAIL,
      Subject: "Message From HelloWorlds Website",
      Body: "Email : " + email
    }).then(
      (message) => {
        if (message === "OK") {
          swal("Thanks!", " " + email + ", Your message is delivered. We will contact you soon.", "success");
          setEmail('')
        } else {
          swal("Opps!", " Something went wrong! Please try again later", "error");
        }
      });
  };

  useEffect(() => {
    document.title = "HelloWorlds: Join Classes and Learning Clubs Run by Educators in 50+ Subjects"
},[])

  return (
    <main>
      <section className={styles.section1}>
        <div className={styles.topSection}>
          <TextAppearAnimation
            text="#MagicOfClubs"
            className={styles.preHeading}
          />
          <MotionDiv direction="right" delay={0.1}>
            <h1 className={styles.heading}>
              <span className="textColorPrimary">WORLDS</span> : Where curious children and inspiring educators {" "}
              <div className={styles.wordAnimationWrapper}>
                <WordAnimation className='textColorPrimary' words={["ideate", "hangout", "build", "learn"]} duration={2000} />
              </div>
            </h1>
          </MotionDiv>
          <Row className={styles.topSectionChild}>
            <Col md={7}>
              <TextAppearAnimation
                text="Explore subjects and passion with online clubs & micro-schools run by educators at 1/10th the cost of dedicated classes"
                className={styles.subHeading}
                colorChangeIndex={13}
              />
            </Col>
            <Col md={5}>
              <MotionDiv direction="right" delay={0.1}>
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}
                  className="buttonPrimary"
                  onClick={() => window.open("https://app.helloworlds.co/", "_blank")}
                >
                  Explore Worlds
                </motion.button>
              </MotionDiv>
            </Col>

          </Row>
        </div>


        <div className={styles.homeTopImg}>
          <FadeInAnimation>
            <LazyImage
              src="/images/worldsDashboard1.webp"
              alt="Worlds Hero Image"
              width="100%"
              height="100%"
            />
          </FadeInAnimation>
        </div>
      </section>

      <section className={styles.section2} ref={section2Ref}>
        <TextAppearAnimation
          text="Traditional Classes Pose 3 Risks"
          center={true}
          className={styles.h3}
          colorChangeIndex={2}
        />

        <MotionDiv delay={0.1}>
          <Row className={styles.boxes}>
            <Col md={4} className='d-flex justify-content-center'>
              <MotionDiv direction="right" delay={0.1}>
                <div className={styles.box}>
                  <p className="text-white">Parents can't afford to experiment with hefty fees for every new class their child likes.</p>
                </div>
              </MotionDiv>

            </Col>
            <Col md={4} className='d-flex justify-content-center'>
              <MotionDiv direction="right" delay={0.4}>
                <div className={styles.box}>
                  <p className="text-white">Nobody keeps track of progress after classes when the child starts to create/build.</p>
                </div>
              </MotionDiv>
            </Col>
            <Col md={4} className='d-flex justify-content-center'>
              <MotionDiv direction="right" delay={0.4}>
                <div className={styles.box}>
                  <p className="text-white">The child doesn't get real-world experience with the educators and other like-minded children.</p>
                </div>
              </MotionDiv>
            </Col>
          </Row>
        </MotionDiv>
      </section>

      <section className={styles.section3}>
        <MotionDiv delay={0.1}>
          <div className={styles.content}>
            <TextAppearAnimation
              text="We solve this with WORLDS, offering a unique, low-commitment avenue for your child to be guided by experienced educators."
              center={true}
              className={styles.h2}
              colorChangeIndex={5}
            />

            <TextAppearAnimation
              text="In a WORLD, learners gather with an educator in a club-style setup, equipped for educator-led activities such as discussions, live webinars/workshops, chats and even celebrations!"
              center={true}
              className={styles.p}
            />

            <div className={styles.mentors}>
              {/* <MotionDiv delay={0.1}>
                <div className={styles.mentorsImg}>
                  <LazyImage
                    src="/images/homeImage2.png"
                    alt="Worlds Hero Image"
                    width="100%"
                    height="100%"
                  />
                </div>
              </MotionDiv> */}

              <MotionDiv delay={0.1}>
                <div className={styles.worldsImage}>
                  <LazyImage
                    src="/images/worldsDashboard2.webp"
                    alt="Worlds Hero Image"
                    width="100%"
                    height="100%"
                  />
                </div>
              </MotionDiv>

              {
                window.innerWidth > 450 ? (
                  <div className={styles.mentorsContent}>
                    <div className={styles.line1} />
                    <MotionDiv direction="right" delay={0.1}>
                      <div className={styles.card}>
                        <LazyImage
                          src="/images/academicGroups.png"
                          alt="Academic Groups"
                        />
                        <h5>
                          Academic Groups
                        </h5>
                      </div>
                    </MotionDiv>
                    <div className={styles.line2} />
                    <MotionDiv direction="right" delay={0.3}>
                      <div className={styles.card}>
                        <LazyImage
                          src="/images/passionClubs.png"
                          alt="Passion Clubs"
                        />
                        <h5>
                          Passion Clubs
                        </h5>
                      </div>
                    </MotionDiv>
                    <div className={styles.line3} />
                    <MotionDiv direction="right" delay={0.3}>
                      <div className={styles.card}>
                        <LazyImage
                          src="/images/careerCommunities.png"
                          alt="Career Communities"
                        />
                        <h5>
                          career Communities
                        </h5>
                      </div>
                    </MotionDiv>
                  </div>
                ) : (
                  <div className={styles.mentorsContentMob}>
                    <MotionDiv delay={0.1}>
                      <div className={styles.mentorsContentMobTop}>
                        <div className={styles.line1} />
                        <div className={styles.card}>
                          <LazyImage
                            src="/images/academicGroups.png"
                            alt="Academic Groups"
                          />
                          <h5>
                            Academic Groups
                          </h5>
                        </div>
                        <div className={styles.line2} />
                        <div className={styles.card}>
                          <LazyImage
                            src="/images/passionClubs.png"
                            alt="Passion Clubs"
                          />
                          <h5>
                            Passion Clubs
                          </h5>
                        </div>
                      </div>
                    </MotionDiv>

                    <MotionDiv delay={0.1}>
                      <div className={styles.mentorsContentMobTopBottom}>
                        <div className={styles.line} />
                        <div className={styles.card}>
                          <LazyImage
                            src="/images/careerCommunities.png"
                            alt="Career Communities"
                          />
                          <h5>
                            career Communities
                          </h5>
                        </div>
                      </div>
                    </MotionDiv>
                  </div>
                )
              }

              <MotionDiv direction="up" delay={0.1}>
                <button
                  className="buttonPrimary"
                  onClick={() => window.open("https://app.helloworlds.co/", "_blank")}
                >
                  Sign Up!
                </button>
              </MotionDiv>
            </div>
          </div>
        </MotionDiv>
      </section>

      <section className={styles.section4}>
        <div className={styles.headings}>
          <MotionDiv direction="down" delay={0.1} noFullHeight={true}>
            <LazyImage
              src="/images/astronaut2.webp"
              alt="Worlds Hero Image"
            />
          </MotionDiv>
          <TextAppearAnimation
            text="An affordable space especially for Homeschooling & Unschooling Parents"
            center={true}
            className={styles.h2}
          />
        </div>

        <div className={styles.content}>
          <div className={styles.boxLeft}>
            <MotionDiv direction="right" delay={0.1} noFullHeight={true}>
              <LazyImage
                src="/images/homeImg2.webp"
                alt="Worlds Hero Image"
              />
            </MotionDiv>
          </div>

          <MotionDiv direction="left" delay={0.1} noFullHeight={true}>
            <div className={styles.boxRight}>
              <h3>
                Live Interactions
              </h3>
              <h5>
                Mentors host workshops, classes, debates, and guest webinars
              </h5>
              <div className={styles.points}>
                <div className={styles.point}>
                  <LazyImage
                    src="/images/point.webp"
                    alt="Worlds Hero Image"
                  />
                  <p>
                    Build confidence to speak or opine
                  </p>
                </div>
                <div className={styles.point}>
                  <LazyImage
                    src="/images/point.webp"
                    alt="Worlds Hero Image"
                  />
                  <p>
                    Level up on passion with live learning
                  </p>
                </div>
                <div className={styles.point}>
                  <LazyImage
                    src="/images/point.webp"
                    alt="Worlds Hero Image"
                  />
                  <p>
                    2 way interactions vs rote monologues
                  </p>
                </div>
              </div>
            </div>
          </MotionDiv>
        </div>

        <div className={styles.content2}>
          <div className={styles.boxLeft}>
            <MotionDiv direction="left" delay={0.1} noFullHeight={true}>
              <LazyImage
                src="/images/homeImg.webp"
                alt="Worlds Hero Image"
              />
            </MotionDiv>
          </div>

          <MotionDiv direction="right" delay={0.1} noFullHeight={true}>
            <div className={styles.boxRight}>
              <h3>
                Group Chats
              </h3>
              <h5>
                Friendships are forged in those tiny moments in group chats
              </h5>
              <div className={styles.points}>
                <div className={styles.point}>
                  <LazyImage
                    src="/images/point.webp"
                    alt="Worlds Hero Image"
                  />
                  <p>
                    Easy sharing of ideas and updates
                  </p>
                </div>
                <div className={styles.point}>
                  <LazyImage
                    src="/images/point.webp"
                    alt="Worlds Hero Image"
                  />
                  <p>
                    Live sync with kids around the world
                  </p>
                </div>
              </div>
            </div>
          </MotionDiv>
        </div>

        <div className={styles.content3}>
          <div className={styles.boxLeft}>
            <MotionDiv direction="right" delay={0.1} noFullHeight={true}>
              <LazyImage
                src="/images/worldsDashboardMobile.webp"
                alt="Worlds Hero Image"
              />
            </MotionDiv>
          </div>

          <MotionDiv direction="left" delay={0.1} noFullHeight={true}>
            <div className={styles.boxRight}>
              <h3>
                Facebook-like Feed
              </h3>
              <h5>
                Mentors create discussion thread and share career/study tips
              </h5>
              <div className={styles.points}>
                <div className={styles.point}>
                  <LazyImage
                    src="/images/point.webp"
                    alt="Worlds Hero Image"
                  />
                  <p>
                    Healthy debates and discussions
                  </p>
                </div>
                <div className={styles.point}>
                  <LazyImage
                    src="/images/point.webp"
                    alt="Worlds Hero Image"
                  />
                  <p>
                    Upskilling with quizzes and contests
                  </p>
                </div>
              </div>
            </div>
          </MotionDiv>
        </div>
      </section>

      <section className={styles.section5}>
        <div className={styles.headings}>
          <TextAppearAnimation
            text="Self-paced, personalised learning takes a futuristic approach"
            center={true}
            className={styles.h2}
          />
          <TextAppearAnimation
            text="Our aim is straightforward: Unleash the next gen's ambition, creativity, and autonomy. We're crafting an educational system that empowers kids to connect with amazing educators, enabling them to flourish, learn, and succeed independently."
            center={true}
            className={styles.p}
          />
          <div className={styles.points}>
            <MotionDiv direction="right" delay={0.1}>
              <p className={styles.point}>
                <AiFillCheckCircle size={25} /> Stress-free Environment
              </p>
            </MotionDiv>
            <MotionDiv direction="down" delay={0.1}>
              <p className={styles.point}>
                <AiFillCheckCircle size={25} /> Move beyond rote learning
              </p>
            </MotionDiv>
            <MotionDiv direction="left" delay={0.1}>
              <p className={styles.point}>
                <AiFillCheckCircle size={25} /> One-on-one attention
              </p>
            </MotionDiv>

          </div>
          <MotionDiv direction="up" delay={0.1}>
            <button
              className="buttonPrimary"
              onClick={() => window.open("https://app.helloworlds.co/", "_blank")}
            >
              Discover Clubs
            </button>
          </MotionDiv>
        </div>
      </section>

      <section className={styles.section6} ref={section6Ref}>
        <MotionDiv direction="down" delay={0.1}>
          <LazyImage
            src="/images/astronaut3.webp"
            alt="Worlds Hero Image"
          />
        </MotionDiv>
        <div className={styles.top}>
          <Row className="w-100">
            <Col md={6}>
              <MotionDiv direction="right" delay={0.3}>
                <div className={styles.topLeft}>
                  <h2>
                    Are we replacing
                  </h2>
                  <p>
                    Online classes?
                  </p>
                </div>
              </MotionDiv>
            </Col>
            <Col md={6}>
              <MotionDiv direction="left" delay={0.5}>
                <div className={styles.topRight}>
                  <p>
                    "Our clubs function to keep your child productively busy."
                  </p>
                  <p>
                    Our current education system underestimates young minds. We, at HelloWorlds, are paving the way for kids to pursue an open curricula where educators are empowered to create their own methods to help kids flourish.
                  </p>
                  <div className={styles.coFounder}>
                    <LazyImage
                      src="/images/Vikshak_passport_edited.webp"
                      alt="Worlds Hero Image"
                    />
                    <div className={styles.content}>
                      <p>Vikshak P</p>
                      <p>Co-Founder & CEO, HelloWorlds</p>
                    </div>
                  </div>
                </div>
              </MotionDiv>
            </Col>
          </Row>

        </div>

        <div className={styles.bottom}>
          <MotionDiv direction="down" delay={0.1}>
            <LazyImage
              src="/images/email.webp"
              alt="Worlds Hero Image"
            />
          </MotionDiv>
          <div className={styles.content}>
            <TextAppearAnimation
              text="Be the first to know when we launch this month! Plus, stay updated on the 50+ Worlds that'll be launched by incredible educators."
              center={true}
              className={styles.h2}
              colorChangeIndex={21}
            />
            <MotionDiv direction="up" delay={0.1}>
              <form className="mt-5" onSubmit={handleSubmit}>
                <Form.Group className='w-75'>
                  <Form.Label>
                    Enter your email here *
                  </Form.Label>
                  <Form.Control
                    type="email"
                    required
                    size='lg'
                    placeholder="Enter your email here"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <button className="buttonPrimary" type='submit'>
                  Notify Me
                </button>
              </form>
            </MotionDiv>
          </div>
        </div>
      </section>

    </main>
  )
}

export default Home