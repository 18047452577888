import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const WordAnimation = ({ words, duration = 2000, className = "" }) => {
    const [currentWordIndex, setCurrentWordIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentWordIndex((prevIndex) =>
                prevIndex === words.length - 1 ? 0 : prevIndex + 1
            );
        }, duration);

        return () => clearInterval(interval);
    }, [duration, words.length]);

    if (!Array.isArray(words) || words.length === 0) return null;

    const currentWord = words[currentWordIndex];

    return (
        <motion.span
            key={currentWordIndex}
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -30, opacity: 0 }}
            transition={{ duration: 0.5 }}
            className={className}
        >
            {currentWord}
        </motion.span>
    );
};

export default WordAnimation;

WordAnimation.propTypes = {
    words: PropTypes.arrayOf(PropTypes.string).isRequired,
    duration: PropTypes.number,
    className: PropTypes.string,
};
