import styles from './About.module.css'
import { LazyImage, MotionDiv, FadeInAnimation, TextAppearAnimation } from '../../components'
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Form } from 'react-bootstrap'
import swal from 'sweetalert';

const About = () => {
    const contactRef = useRef(null);
    const location = useLocation();
    const [formData, setformData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: ""
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        const { firstName, lastName, email, phone, message } = formData
        window.Email.send({
            Host: "smtp.elasticemail.com",
            Username: process.env.REACT_APP_USER_NAME,
            Password: process.env.REACT_APP_PASSWORD,
            To: process.env.REACT_APP_TO_EMAIL,
            From: process.env.REACT_APP_FROM_EMAIL,
            Subject: "Message From HelloWorlds Website",
            Body: "Name : " + firstName + " " + lastName
                + "<br> Email : " + email
                + "<br> Phone Number : " + phone
                + "<br> message : " + message
        }).then(
            (message) => {
                if (message === "OK") {
                    swal("Thanks!", " " + firstName + ", Your message is delivered. We will contact you soon.", "success");
                    setformData({ firstName: "", lastName: "", email: "", phone: "", message: "" })
                } else {
                    swal("Opps!", " " + firstName + " Something went wrong! Please try again later", "error");
                }
            });
    }

    useEffect(() => {
        if (location.search === '?contact') {
            contactRef?.current?.scrollIntoView({ behavior: 'smooth' })
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }, [location.search])

    useEffect(() => {
        document.title = "About | HelloWorlds"
    },[])

    return (
        <main className={styles.about}>
            <div className={styles.headings}>
                <TextAppearAnimation
                    text="We are on a mission to make the world a child's classroom."
                    className={styles.heading}
                    center={true}
                />
            </div>

            <div className={styles.content}>
                <div className={styles.boxLeft}>
                    <MotionDiv direction="right" delay={0.1} noFullHeight={true}>
                        <LazyImage
                            src="/images/aboutImg.webp"
                            alt="Worlds Hero Image"
                        />
                    </MotionDiv>
                </div>

                <MotionDiv direction="left" delay={0.1} noFullHeight={true}>
                    <div className={styles.boxRight}>
                        <h3>
                            Our Mission
                        </h3>
                        <p>
                            Our ability to prepare learners for their future and the future of the world is directly tied to our values, their passion, our abilities and our expertise. We come from many vocations, as educators, technologists, storytellers and a whole bunch of innovators.
                        </p>
                        <p>
                            While our differences are many, our intentions are the same: to do all we can to create the Practical School for the world, and in doing so help young learners around India, create lives of meaning, satisfaction and consequence
                        </p>

                    </div>
                </MotionDiv>
            </div>

            <div className={styles.content2}>
                <div className={styles.boxLeft}>
                    <MotionDiv direction="left" delay={0.1} noFullHeight={true}>
                        <LazyImage
                            src="/images/aboutImg2.webp"
                            alt="Worlds Hero Image"
                        />
                    </MotionDiv>
                </div>

                <MotionDiv direction="right" delay={0.1} noFullHeight={true}>
                    <div className={styles.boxRight}>
                        <h3>
                            Our Team
                        </h3>
                        <p>
                            We are an enthusiastic bunch, deeply in love with helping children fall in love with experiential learning. We are a technology-first venture, providing a personalised learning experience for all the unique, young dreamers out there. Talk to us whenever. We know what you're going through.
                        </p>
                        <p>
                            We are always looking for enthusiastic folks from various backgrounds and geographies to host their online club a.k.a a World on our platform and make a difference in the lives of young minds in India. Come, be a part of the rise of influential learning!
                        </p>

                    </div>
                </MotionDiv>
            </div>

            <FadeInAnimation>
                <div className={styles.contactForm} ref={contactRef}>
                    <MotionDiv delay={0.1}>
                        <h6 className='text-center'>
                            Contact Us
                        </h6>
                        <p className='text-center'>
                            Drop us a line with any questions, inquiries or business proposals
                        </p>
                    </MotionDiv>
                    <div className={styles.formContainer}>
                        <form onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <MotionDiv delay={0.12}>
                                    <Form.Group as={Col}>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            value={formData.firstName}
                                            onChange={(e) => setformData({ ...formData, firstName: e.target.value })}
                                        />
                                    </Form.Group>
                                </MotionDiv>

                                <MotionDiv delay={0.14}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={formData.lastName}
                                            onChange={(e) => setformData({ ...formData, lastName: e.target.value })}
                                        />
                                    </Form.Group>
                                </MotionDiv>

                            </Row>
                            <Row className="mb-3">
                                <MotionDiv delay={0.16}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            required
                                            value={formData.email}
                                            onChange={(e) => setformData({ ...formData, email: e.target.value })}
                                        />
                                    </Form.Group>
                                </MotionDiv>

                                <MotionDiv delay={0.18}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control
                                            type="number"
                                            required
                                            value={formData.phone}
                                            onChange={(e) => setformData({ ...formData, phone: e.target.value })}
                                        />
                                    </Form.Group>
                                </MotionDiv>
                            </Row>
                            <MotionDiv delay={0.2}>
                                <Form.Group className="mb-5">
                                    <Form.Label>Leave us a message...</Form.Label>
                                    <Form.Control
                                        as="textarea" rows={5}
                                        value={formData.message}
                                        onChange={(e) => setformData({ ...formData, message: e.target.value })}
                                    />
                                </Form.Group>
                            </MotionDiv>
                            <MotionDiv delay={0.22}>
                                <div className="text-center">
                                    <button type="submit" className="buttonPrimary">Submit</button>
                                </div>
                            </MotionDiv>
                        </form>
                    </div>

                </div>
            </FadeInAnimation>
        </main>
    )
}

export default About