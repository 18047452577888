import { Modal, Spinner } from 'react-bootstrap';
import PropTypes from "prop-types";
import styles from './Styles.module.css';
import { LazyImage, MotionDiv } from '../';
import { motion } from 'framer-motion';

const FormModal = ({ show, handleClose, currentStep, handleNextStep, handlePrevStep, totalSteps, handleSubmit, loading, children }) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <div className={styles.modal}>
                <div className={styles.header}>
                    <motion.button
                        onClick={handleClose}
                        whileTap={{ scale: 0.9 }}
                    >
                        <LazyImage src="/cross.png" alt="arrow-up" width="100%" />
                    </motion.button>
                    <h6>
                        {`Step ${currentStep}/${totalSteps}`}
                    </h6>
                </div>
                <div className={styles.content}>
                    {children}
                </div>
                <div className={styles.footer}>
                    {
                        currentStep > 1 && (
                            <MotionDiv direction="right">
                                <button className="buttonPrimary" onClick={handlePrevStep}>
                                    Back
                                </button>
                            </MotionDiv>

                        )
                    }
                    {
                        currentStep < totalSteps ? (
                            <MotionDiv direction="left">
                                <button className="buttonPrimary" onClick={handleNextStep}>
                                    Next
                                </button>
                            </MotionDiv>

                        ) : (
                            <button className="buttonPrimary" onClick={handleSubmit} style={{ minWidth: "95px" }}>
                                {
                                    loading ? (
                                        <Spinner animation="border" variant="light" size="sm" />
                                    ) : (
                                        'Submit'
                                    )
                                }
                            </button>
                        )
                    }
                </div>
            </div>
        </Modal>
    );
}

export default FormModal;

FormModal.propTypes = {
    show: PropTypes.bool.isRequired,
    children: PropTypes.element.isRequired,
    currentStep: PropTypes.number.isRequired,
    totalSteps: PropTypes.number.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleNextStep: PropTypes.func.isRequired,
    handlePrevStep: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

