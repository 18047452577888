import styles from './Footer.module.css'
import { Row, Col } from 'react-bootstrap'
import { LazyImage } from ".."
import { Link } from 'react-router-dom'
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa"
import { AiOutlineMail, AiFillPhone } from "react-icons/ai"
import { facebook, instagram, twitter } from "../../links"
import { motion } from "framer-motion"

const Footer = () => {
  const handleClick = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  return (
    <motion.div
      whileInView={{ y: [20, 50, 0], opacity: [0, 0, 1] }}
      transition={{ duration: 0.5 }}
    >
      <footer className={styles.footer}>
        <div className={styles.border}></div>
        <div className={styles.footerTop}>
          <Row>
            <Col md={3}>
              <LazyImage
                src="/logo.png"
                alt="logo"
              />
            </Col>

            <Col md={3}>
              <div className={styles.links}>
                <Link to="/carrers" onClick={handleClick}>
                  Careers
                </Link>
                <Link to="/about">
                  About Us
                </Link>
                <Link to="/blog" onClick={handleClick}>
                  Blog
                </Link>
                <Link to="/about?contact">
                  Contact
                </Link>
                <a
                  href="https://drive.google.com/file/d/1Fh3r8YVep2SdXenw9xmL1r4pHokqe4AK/view?pli=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  Cancellation Policy
                </a>
                <a
                  href="https://drive.google.com/file/d/19glRh5FmxUERV7gwG7saBZUFUj6k7TcH/view"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms
                </a>
                <a
                  href="https://drive.google.com/file/d/1_c5Oxmk_B9VtRPfQvd-byNtFz_X9TBp3/view"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy
                </a>
              </div>
            </Col>

            <Col md={3}>
              <div className={styles.contact}>
                <p>
                  <a href="tel:+91 9043408421">
                    <AiFillPhone /> Phone : +91 9043408421
                  </a>
                </p>
                <p>
                  <a href="mailto:hello@helloworlds.co">
                    <AiOutlineMail /> Email :  hello@helloworlds.co
                  </a>
                </p>
              </div>
              <div className={styles.address}>
                <p>
                  #19, "Aarambha", Jakkur Main Road, Jakkur, Bangalore, KA, India
                </p>
              </div>
            </Col>

            <Col md={3}>
              <div className={styles.text}>
                <p>
                  Come, let's make the world a child's classroom!
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <div className={styles.footerBottom}>
          <p>
            Copyright © {new Date().getFullYear()} GlobalShaala Learning OPC Pvt. Ltd.
          </p>
          <div className={styles.social}>
            <a href={facebook} target="_blank" rel="noreferrer">
              <FaFacebook />
            </a>
            <a href={instagram} target="_blank" rel="noreferrer">
              <FaInstagram />
            </a>
            <a href={twitter} target="_blank" rel="noreferrer">
              <FaTwitter />
            </a>
          </div>
        </div>
      </footer>
    </motion.div>
  )
}

export default Footer