import { Home, About, Educators } from "./containers"
import { Route, Routes } from "react-router-dom"

const Router = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/for-educators" element={<Educators />} />
            <Route path="*" element={<Home />} />
        </Routes>
    )
}

export default Router