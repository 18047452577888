import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

const MultiSelect = ({ options, selected, setSelected, name, id }) => {
    return (
        <DropdownMultiselect
            handleOnChange={setSelected}
            options={options}
            selected={selected}
            placeholder="Select"
            name={name}
            id={id}
        />
    )
}

export default MultiSelect