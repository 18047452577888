import React, { useState, useEffect } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import { motion } from 'framer-motion';
import styles from './Header.module.css';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location])

  return (
    <motion.header
      className={`${styles.navbar} ${isScrolled ? styles.scrolled : isMenuOpen ? styles.menuOpen : ''}`}
      initial={{ backgroundColor: isMenuOpen ? 'var(--background_primary)' : 'transparent' }}
      animate={{ backgroundColor: isScrolled ? 'var(--background_primary)' : isMenuOpen ? 'var(--background_primary)' : 'transparent' }}
      transition={{ duration: 0.5 }}
    >

      <div className={styles.navbarLogo}>
        <Link to="/">
          <img src="/logo.png" alt="HelloWorlds" />
        </Link>
      </div>
      <nav className={`${styles.navbarMenu} ${isMenuOpen ? styles.open : ''}`}>
        <Link
          to="/about"
          className={location.pathname === '/about' && !location?.search ? 'textColorSecondary' : ''}
        >
          About
        </Link>
        <Link
          to="/about?contact"
          className={location?.search === '?contact' ? 'textColorSecondary' : ''}
        >
          Contact
        </Link>
        <Link
          to="/for-educators"
          className={location.pathname === '/for-educators' ? 'textColorSecondary' : ''}
        >
          For Educators
        </Link>
      </nav>
      <div className={styles.navbarToggle} onClick={toggleMenu}>
        {isMenuOpen ? <FiX size={35} /> : <FiMenu size={35} />}
      </div>
    </motion.header>
  );
};

export default Header;
