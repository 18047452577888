
export const UPDATE_FORM_FIELD = 'UPDATE_FORM_FIELD';
export const RESET_FORM_DATA = 'RESET_FORM_DATA';

// Reducer
export const formReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FORM_FIELD:
            return {
                ...state,
                [action.field]: action.value,
            };

        case RESET_FORM_DATA:
            return action.initialState

        default:
            return state;
    }
};