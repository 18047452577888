import { BrowserRouter } from "react-router-dom"
import "./App.css"
import { Footer, Header } from "./components"
import Router from "./Router"
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from "./ScrollToTop"
import { ProgressBar, ScrollToTopButton } from "./components"

export default function App() {
  return (
    <div className="App" style={{ backgroundImage: "url(/backgrounds/bg.webp" }}>
      <BrowserRouter>
        <ScrollToTop />
        <ProgressBar />
        <Header />
        <Router />
        <Footer />
        <ScrollToTopButton />
      </BrowserRouter>
    </div>
  )
}