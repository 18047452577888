import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const LazyImage = ({src, alt, height, width }) => (
    <LazyLoadImage
        alt={alt}
        effect="blur"
        src={src}
        height={height}
        width={width}
    />
);

export default LazyImage;