import styles from "./Educators.module.css"
import { FormModal, LazyImage, TextAppearAnimation, MultiSelect } from "../../components"
import { Row, Col, Container, Form } from "react-bootstrap"
import { AiFillCheckCircle } from "react-icons/ai"
import { MotionDiv } from '../../components'
import { useEffect, useMemo, useReducer, useState } from "react"
import { formReducer, UPDATE_FORM_FIELD, RESET_FORM_DATA } from './formReducer'
import swal from 'sweetalert'

const clubNames = [
    'The Homeschoolers Adda',
    'History Buffs Club',
    'Guitar Rockstars',
    'Photography Hub',
    'Android Devs Corner',
    'Spanish Club for Beginners',
    'Math Grade 6 Club',
    'A Club for Aspiring YouTubers',
    'String Serenaders',
    'Tabla Tunesmiths',
    'Artistry Assemble Club',
    'The Calculus Crew',
    'Natya Nurturers',
]

const initialState = {
    fullName: "",
    organisationName: "",
    organisationWebsite: "",
    email: "",
    phoneNumber: "",
    gender: "Female",
    currentCity: "",
    publicProfile: "",
    anyExperience: "No",
    topics: [],
    grade: [],
    timing: [],
    subjectExperience: "",
    uniqueOffering: "",
    careerOpportunities: "Yes, I'm happy to share what I know and have learnt",
    oneOnOneMentorship: "",
    anyQuestions: "",
}

const FORMSTEPS = 4

const Educators = () => {

    const subjectOptions = useMemo(() => {
        return [
            "Arts & Crafts",
            "Beauty and Wellness",
            "Blogging/Vlogging",
            "Bricks and Blocks",
            "Business",
            "Cooking or Baking",
            "Creative writing",
            "Dance",
            "DJ & Sound Design",
            "Entrepreneurship",
            "Exercise, Workouts or Fitness",
            "Fashion",
            "Film making",
            "Finance & Economics",
            "Games and Puzzles",
            "Gardening and Agriculture",
            "Geography",
            "History and Culture",
            "Humanities",
            "Intra-personal development (habits/hobbies/mental agility/concentration etc.)",
            "Language (Indian or world)",
            "Leadership and Management",
            "Legal and Accounting",
            "Music (Vocals or Instruments)",
            "Nutrition",
            "Photography",
            "Public Speaking and Oratory skills",
            "Robotics",
            "Science (Physics/Chem/Bio/EVS) or Mathematics",
            "Social Awareness",
            "Social Cause",
            "Sports",
            "Story telling",
            "Stuff on computers (Coding/Web design/Game design/Microsoft Office/Graphic Design)",
            "Theatre, Comedy or Acting",
            "A certain career (Engineering/Medicine/Law/Fashion Design/Youtuber etc.)",
            "Special Kids (ADHD/Dyslexia etc.)",
            "Others",
        ]
    }, [])

    const gradeOptions = useMemo(() => {
        return [
            "1st to 4th std",
            "5th to 7th std",
            "8th to 10th std",
            "11th and 12th std",
            "1st to 6th std",
            "7th to 12th",
            "Not Sure",
        ]
    }, [])

    const timingOptions = useMemo(() => {
        return [
            "Weekdays in the mornings",
            "Weekdays in the evenings/nights",
            "Weekends in the morning/afternoon",
            "Weekends in the evenings/nights",
            "Not Sure"
        ]
    }, [])

    const [formData, dispatch] = useReducer(formReducer, initialState);
    const [showFormMoal, setShowFormModal] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleDataChange = (field, value) => {
        dispatch({ type: UPDATE_FORM_FIELD, field, value });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (!isFormValid()) {
            setError("Please fill all the required fields")
            return
        }
        if (loading) return
        setError(null)
        setLoading(true)
        const {
            fullName,
            organisationName,
            organisationWebsite,
            email,
            phoneNumber,
            gender,
            currentCity,
            publicProfile,
            anyExperience,
            topics,
            grade,
            timing,
            subjectExperience,
            uniqueOffering,
            careerOpportunities,
            oneOnOneMentorship,
            anyQuestions,
        } = formData
        window.Email.send({
            Host: "smtp.elasticemail.com",
            Username: process.env.REACT_APP_USER_NAME,
            Password: process.env.REACT_APP_PASSWORD,
            To: process.env.REACT_APP_TO_EMAIL,
            From: process.env.REACT_APP_FROM_EMAIL,
            Subject: "Educator Application",
            Body: "Full Name : " + fullName
                + "<br> Organisation name : " + organisationName
                + "<br> Organisation website : " + organisationWebsite
                + "<br> Email : " + email
                + "<br> Phone number : " + phoneNumber
                + "<br> Gender : " + gender
                + "<br> Your current city (country, if outside India) : " + currentCity
                + "<br> Any public profile URL (facebook/youtube/insta/linkedin). Mainly to confirm legitimacy. : " + publicProfile
                + "<br> Do you have any experience teaching kids? (It's not mandatory to have) : " + anyExperience
                + "<br> What topic(s) would you like to teach/mentor on? : " + topics
                + "<br> Target grades : " + grade
                + "<br> What days of the week/times of the day do you see yourself being active in your World? (keep in mind the kids have schools during the day) : " + timing
                + "<br> Tell us about you and your experience in the subject(s) you've chosen. : " + subjectExperience
                + "<br> Tell us what kids can learn from you OR your unique offering you are bringing to the table for kids. : " + uniqueOffering
                + "<br> Can you help children understand more about the career opportunities and trends in your field? : " + careerOpportunities
                + "<br> Do you like to mentor children one on one? If yes, what sort of problems do you think you can address through your one on one mentorship with children? If no, you may skip this. : " + oneOnOneMentorship
                + "<br> Any questions/comments? Feel free. : " + anyQuestions
        }).then(
            (message) => {
                if (message === "OK") {
                    swal("Thanks!", " " + fullName + ", Your message is delivered. We will contact you soon.", "success");
                    setLoading(false)
                    handleCloseFormModal()
                } else {
                    swal("Opps!", " " + fullName + " Something went wrong! Please try again later", "error");
                    setLoading(false)
                    handleCloseFormModal()
                }
            });
    };

    const isFormValid = () => {
        const required = [
            "fullName",
            "email",
            "phoneNumber",
            "gender",
        ]

        switch (currentStep) {
            case 2:
                required.push("currentCity")
                break;

            case 3:
                required.push("anyExperience")
                required.push("topics")
                required.push("grade")
                required.push("timing")
                required.push("uniqueOffering")
                break;

            case 4:
                required.push("subjectExperience")
                required.push("careerOpportunities")
                break;

            default:
                break;
        }

        const isRequiredValid = required.every((field) => {
            return formData[field].length !== 0
        })

        return isRequiredValid;
    }

    const handleOpenFormModal = () => {
        setShowFormModal(true);
    }

    const handleCloseFormModal = () => {
        setError(null)
        setShowFormModal(false);
        setCurrentStep(1);
        dispatch({ type: RESET_FORM_DATA, initialState });
    }

    const handleNextStep = () => {
        if (!isFormValid()) {
            setError("Please fill all the required fields")
            return
        }
        setError(null)
        if (currentStep < FORMSTEPS) {
            setCurrentStep(currentStep + 1);
        }
    }

    const handlePrevStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    }

    const renderForm = () => {
        let formContent;
        switch (currentStep) {
            case 1:
                formContent = (
                    <div>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className={
                                    error && formData.fullName.length === 0 ? "text-danger" : ""
                                }
                            >
                                Your full name *
                            </Form.Label>
                            <Form.Control
                                type="text"
                                required
                                value={formData.fullName}
                                onChange={(e) => handleDataChange('fullName', e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label
                                className={
                                    error && formData.email.length === 0 ? "text-danger" : ""
                                }
                            >
                                Email *
                            </Form.Label>
                            <Form.Control
                                type="text"
                                required
                                value={formData.email}
                                onChange={(e) => handleDataChange('email', e.target.value)}
                            />
                        </Form.Group>


                        <Form.Group className="mb-3">
                            <Form.Label
                                className={
                                    error && formData.phoneNumber.length === 0 ? "text-danger" : ""
                                }
                            >
                                Phone number *
                            </Form.Label>
                            <Form.Control
                                type="number"
                                required
                                value={formData.phoneNumber}
                                onChange={(e) => handleDataChange('phoneNumber', e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label
                                className={
                                    error && formData.gender.length === 0 ? "text-danger" : ""
                                }
                            >
                                Gender *
                            </Form.Label>
                            <Form.Select
                                required
                                value={formData.gender}
                                onChange={(e) => handleDataChange('gender', e.target.value)}
                            >
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Rather not say">Rather not say</option>
                            </Form.Select>
                        </Form.Group>

                    </div>
                );
                break;
            case 2:
                formContent = (
                    <div>
                        <Form.Group className="mb-3">
                            <Form.Label>Organisation name</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.organisationName}
                                onChange={(e) => handleDataChange('organisationName', e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Organisation website</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.organisationWebsite}
                                onChange={(e) => handleDataChange('organisationWebsite', e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label
                                className={
                                    error && formData.currentCity.length === 0 ? "text-danger" : ""
                                }
                            >
                                Your current city (country, if outside India) *
                            </Form.Label>
                            <Form.Control
                                type="text"
                                required
                                value={formData.currentCity}
                                onChange={(e) => handleDataChange('currentCity', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Any public profile URL (facebook/youtube/insta/linkedin). Mainly to confirm legitimacy.
                            </Form.Label>
                            <Form.Control
                                type="url"
                                required
                                value={formData.publicProfile}
                                onChange={(e) => handleDataChange('publicProfile', e.target.value)}
                            />
                        </Form.Group>
                    </div >
                );
                break;
            case 3:
                formContent = (
                    <div>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className={
                                    error && formData.anyExperience.length === 0 ? "text-danger" : ""
                                }
                            >
                                Do you have any experience teaching kids? (It's not mandatory to have) *
                            </Form.Label>
                            <Form.Select
                                required
                                value={formData.anyExperience}
                                onChange={(e) => handleDataChange('anyExperience', e.target.value)}
                            >
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label
                                className={
                                    error && formData.topics.length === 0 ? "text-danger" : ""
                                }
                            >
                                What topic(s) would you like to teach/mentor on? *
                            </Form.Label>
                            <MultiSelect
                                options={subjectOptions}
                                selected={formData.topics}
                                setSelected={(value) => handleDataChange('topics', value)}
                                name="topics"
                                id="topics"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label
                                className={
                                    error && formData.grade.length === 0 ? "text-danger" : ""
                                }
                            >
                                Target grades *
                            </Form.Label>
                            <MultiSelect
                                options={gradeOptions}
                                selected={formData.grade}
                                setSelected={(value) => handleDataChange('grade', value)}
                                name="grade"
                                id="grade"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label
                                className={
                                    error && formData.timing.length === 0 ? "text-danger" : ""
                                }
                            >
                                What days of the week/times of the day do you see yourself being active in your World? (keep in mind the kids have schools during the day) *
                            </Form.Label>
                            <MultiSelect
                                options={timingOptions}
                                selected={formData.timing}
                                setSelected={(value) => handleDataChange('timing', value)}
                                name="timing"
                                id="timing"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label
                                className={
                                    error && formData.uniqueOffering.length === 0 ? "text-danger" : ""
                                }
                            >
                                Tell us what kids can learn from you OR your unique offering you are bringing to the table for kids. *
                            </Form.Label>
                            <Form.Control
                                required
                                as="textarea"
                                rows={2}
                                value={formData.uniqueOffering}
                                onChange={(e) => handleDataChange('uniqueOffering', e.target.value)}
                            />
                        </Form.Group>
                    </div>
                );
                break;
            case 4:
                formContent = (
                    <div>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className={
                                    error && formData.subjectExperience.length === 0 ? "text-danger" : ""
                                }
                            >
                                Tell us about you and your experience in the subject(s) you've chosen. *
                            </Form.Label>
                            <Form.Control
                                required
                                as="textarea"
                                rows={2}
                                value={formData.subjectExperience}
                                onChange={(e) => handleDataChange('subjectExperience', e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label
                                className={
                                    error && formData.careerOpportunities.length === 0 ? "text-danger" : ""
                                }
                            >
                                Can you help children understand more about the career opportunities and trends in your field? *
                            </Form.Label>
                            <Form.Select
                                required
                                value={formData.careerOpportunities}
                                onChange={(e) => handleDataChange('careerOpportunities', e.target.value)}
                            >
                                <option value="Yes, I'm happy to share what I know and have learnt">
                                    Yes, I'm happy to share what I know and have learnt
                                </option>
                                <option value="No, I would stick to my core offering">
                                    No, I would stick to my core offering
                                </option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>
                                Do you like to mentor children one on one? If yes, what sort of problems do you think you can address through your one on one mentorship with children? If no, you may skip this.
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                value={formData.oneOnOneMentorship}
                                onChange={(e) => handleDataChange('oneOnOneMentorship', e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>
                                Any questions/comments? Feel free.
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                value={formData.anyQuestions}
                                onChange={(e) => handleDataChange('anyQuestions', e.target.value)}
                            />
                        </Form.Group>

                    </div>
                );
                break;
            default:
                formContent = null;
        }
        return formContent;
    };

    useEffect(() => {
        document.title = "Educators | HelloWorlds"
    },[])

    return (
        <main className={styles.educators}>
            <section className={styles.section1}>
                <Row>
                    <Col md={6}>
                        <div className={styles.section1Left}>
                            <TextAppearAnimation
                                text="Have a teachable skill? Run an online club or a micro-school with WORLDS"
                                className={styles.h2}
                            />
                            <TextAppearAnimation
                                text="Inviting educators, creators and professionals to bring learners together in this fun, interactive forum and build a new source of income"
                                className={styles.p}
                            />
                            <div className={styles.section1LeftBtn}>
                                <MotionDiv direction="down" delay={0.1}>
                                    <LazyImage
                                        src="/images/educatorsBtn.webp"
                                        alt="HelloWorlds"
                                    />
                                </MotionDiv>
                                <MotionDiv direction="up" delay={0.1}>
                                    <button
                                        className="buttonGradiant"
                                        onClick={handleOpenFormModal}
                                    >
                                        Apply Now
                                    </button>
                                </MotionDiv>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={styles.section1Right}>
                            <LazyImage
                                src="/images/educators.webp"
                                alt="hello worlds eductors"
                            />
                        </div>
                    </Col>
                </Row>
            </section>

            <section className={styles.section2}>
                <div className={styles.section2Top}>
                    <Row>
                        <Col md={6}>
                            <div className={styles.section2TopLeft}>
                                <LazyImage
                                    src={window.innerWidth > 768 ? "/images/eduSection.webp" : "/images/eduSectionMob.webp"}
                                    alt="hello worlds eductors"
                                    width="100%"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <MotionDiv direction="left" delay={0.1}>
                                <div className={styles.section2TopRight}>
                                    <h6>
                                        What is a WORLD?
                                    </h6>

                                    <h2>
                                        Your Club of Learning.
                                    </h2>
                                    <p className={styles.line} />

                                    <p>
                                        The new-age adolescents seek to transcend the traditional teacher-learner dynamic and crave your guidance beyond classes. So, we created a space where you run an online club or micro-school to bring together like-minded kids to learn collaboratively, offer feedback on their creations, engage in live sessions, and much more!
                                    </p>

                                    <button
                                        className="buttonGradiant"
                                        onClick={handleOpenFormModal}
                                    >
                                        Apply Now
                                    </button>
                                </div>
                            </MotionDiv>
                        </Col>
                    </Row>
                </div>

                <Container className={styles.section2Bottom}>
                    {
                        window.innerWidth < 768 && (
                            <div className={styles.btns}>
                                <div>
                                    Academic Groups.
                                </div>
                                <div>
                                    Passion Clubs.
                                </div>
                                <div>
                                    Career Communities.
                                </div>
                            </div>
                        )
                    }
                    {
                        window.innerWidth < 768 && (
                            <div className={styles.animated}>

                                <div className={styles.animationContainer}>
                                    <div className={styles.animationWrapper}>
                                        {clubNames.slice(0, 4).map((name, index) => (
                                            <span key={index} className={styles.name}>
                                                {name}
                                            </span>
                                        ))}
                                    </div>
                                    <div className={styles.animationWrapper}>
                                        {clubNames.slice(4, 7).map((name, index) => (
                                            <span key={index} className={styles.name}>
                                                {name}
                                            </span>
                                        ))}
                                    </div>
                                    <div className={styles.animationWrapper}>
                                        {clubNames.slice(7, 12).map((name, index) => (
                                            <span key={index} className={styles.name}>
                                                {name}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )

                    }
                    <MotionDiv delay={0.1}>
                        <Row className={styles.section2BottomWrapper}>
                            <Col md={6}>
                                <div className={styles.animated}>
                                    <div className={styles.btns}>
                                        <div>
                                            Academic Groups.
                                        </div>
                                        <div>
                                            Passion Clubs.
                                        </div>
                                        <div>
                                            Career Communities.
                                        </div>
                                    </div>

                                    <div className={styles.animationContainer}>
                                        <div className={styles.animationWrapper}>
                                            {clubNames.slice(0, 4).map((name, index) => (
                                                <span key={index} className={styles.name}>
                                                    {name}
                                                </span>
                                            ))}
                                        </div>
                                        <div className={styles.animationWrapper}>
                                            {clubNames.slice(4, 7).map((name, index) => (
                                                <span key={index} className={styles.name}>
                                                    {name}
                                                </span>
                                            ))}
                                        </div>
                                        <div className={styles.animationWrapper}>
                                            {clubNames.slice(7, 11).map((name, index) => (
                                                <span key={index} className={styles.name}>
                                                    {name}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col md={6}>
                                <div className={styles.content}>
                                    <h6 className="text-center">
                                        Create clubs on subjects or niche topics
                                    </h6>

                                    <p className="text-center">
                                        You can create a World on broader <span className="text-success">subjects like Maths or Graphic Design or niche themes like Calculus or Homeschooling.</span> This online ecoverse is where kids discover new interests, keep their existing passion alive, and find their tribes, joining other like-minded kids and educators they love. Let them fall in love with you because YOU are unique❤️
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </MotionDiv>

                    <div className={styles.image}>
                        <MotionDiv delay={0.1}>
                            <LazyImage
                                src="/images/worldsDashboard1.webp"
                                alt="hello worlds eductors"
                                width="100%"
                            />
                        </MotionDiv>
                    </div>

                </Container>

            </section>

            <section className={styles.section3}>
                <Container fluid="lg">
                    <div className={styles.top}>
                        <Row>
                            <Col md={6}>
                                <MotionDiv direction="right" delay={0.1}>
                                    <div className={styles.left}>
                                        <h6>
                                            Users pay and enter. You decide the monthly subscription fee.
                                        </h6>
                                        <div className={styles.point}>
                                            <p>
                                                <AiFillCheckCircle color="green" size={40} />
                                            </p>
                                            <p>
                                                Think of your World as a club that requires a membership fee.
                                            </p>
                                        </div>

                                        <div className={styles.point}>
                                            <p>
                                                <AiFillCheckCircle color="green" size={40} />
                                            </p>
                                            <p>
                                                You have full autonomy to decide the upper limit of members and the way it's run.
                                            </p>
                                        </div>
                                        <div className={styles.point}>
                                            <p>
                                                <AiFillCheckCircle color="green" size={40} />
                                            </p>
                                            <p>
                                                Wish to start a FREE club? Go ahead! As you build confidence, you can charge later.
                                            </p>
                                        </div>
                                    </div>
                                </MotionDiv>
                            </Col>

                            <Col md={6}>
                                <div className={styles.right}>
                                    <MotionDiv direction="left" delay={0.1}>
                                        <LazyImage
                                            src="/images/eduSection2.webp"
                                            alt="hello worlds eductors"
                                            width="100%"
                                        />
                                    </MotionDiv>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className={styles.bottom}>
                        <h6 className="text-center">
                            Teach. Share. Enable.
                        </h6>
                        <div className={styles.wrapper}>
                            <div className={styles.card}>
                                <LazyImage
                                    src="/images/eduSection3.webp"
                                    alt="hello worlds eductors"
                                    width="100%"
                                />
                                <div className={styles.caption}>
                                    <p>
                                        Host classes, debates and video-chats
                                    </p>
                                </div>
                            </div>

                            <div className={styles.card}>
                                <LazyImage
                                    src="/images/eduSection4.webp"
                                    alt="hello worlds eductors"
                                    width="100%"
                                />
                                <div className={styles.caption}>
                                    <p>
                                        Create content via knowledge & discussion threads
                                    </p>
                                </div>
                            </div>

                            <div className={styles.card}>
                                <LazyImage
                                    src="/images/eduSection5.webp"
                                    alt="hello worlds eductors"
                                    width="100%"
                                />
                                <div className={styles.caption}>
                                    <p>
                                        Run challenges & collaborative projects
                                    </p>
                                </div>
                            </div>

                            <div className={styles.card}>
                                <LazyImage
                                    src="/images/eduSection6.webp"
                                    alt="hello worlds eductors"
                                    width="100%"
                                />
                                <div className={styles.caption}>
                                    <p>
                                        Share feedback on their work & assignments
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.features}>
                        <div className={styles.feature}>
                            <Row>
                                <Col md={6}>
                                    <MotionDiv direction="right" >
                                        <div className={styles.content}>
                                            <h6>
                                                Host classes & debates
                                            </h6>
                                            <p>
                                                Run your World as a micro-school. We have a fully integrated Zoom and calendar that enables you to build schedules of your sessions, which can be classes, workshops, webinars, discussions or just video-chats!
                                            </p>
                                        </div>
                                    </MotionDiv>
                                </Col>

                                <Col md={6}>
                                    <MotionDiv direction="left" >
                                        <div className={styles.img}>
                                            <LazyImage
                                                src="/images/feature1.webp"
                                                alt="hello worlds eductors"
                                                width="100%"
                                            />
                                        </div>
                                    </MotionDiv>
                                </Col>
                            </Row>
                        </div>

                        {
                            window.innerWidth > 768 ? (
                                <div className={styles.feature}>
                                    <Row>
                                        <Col md={6}>
                                            <MotionDiv direction="right" >
                                                <div className={styles.img}>
                                                    <LazyImage
                                                        src="/images/feature2.webp"
                                                        alt="hello worlds eductors"
                                                        width="100%"
                                                    />
                                                </div>
                                            </MotionDiv>
                                        </Col>

                                        <Col md={6}>
                                            <MotionDiv direction="left" >
                                                <div className={styles.content}>
                                                    <h6>
                                                        Provide feedback
                                                    </h6>
                                                    <p>
                                                        Whether it's an assignment or a creation, you can review their work and provide real-time feedback during your live sessions or group chats.
                                                    </p>
                                                </div>
                                            </MotionDiv>
                                        </Col>


                                    </Row>
                                </div>
                            ) : (
                                <div className={styles.feature}>
                                    <Row>
                                        <Col md={6}>
                                            <MotionDiv direction="right" >
                                                <div className={styles.content}>
                                                    <h6>
                                                        Provide feedback
                                                    </h6>
                                                    <p>
                                                        Whether it's an assignment or a creation, you can review their work and provide real-time feedback during your live sessions or group chats.
                                                    </p>
                                                </div>
                                            </MotionDiv>
                                        </Col>

                                        <Col md={6}>
                                            <MotionDiv direction="left" >
                                                <div className={styles.img}>
                                                    <LazyImage
                                                        src="/images/feature2Mob.webp"
                                                        alt="hello worlds eductors"
                                                        width="100%"
                                                    />
                                                </div>
                                            </MotionDiv>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }

                        <div className={styles.feature}>
                            <Row>
                                <Col md={6}>
                                    <MotionDiv direction="right" >
                                        <div className={styles.content}>
                                            <h6>
                                                Create content
                                            </h6>
                                            <p>
                                                Sensitise your young audience about your subject, research, cause, and industry in general via content in text/image/video formats (just like FB groups). You can also use this as discussion forums.
                                            </p>
                                        </div>
                                    </MotionDiv>
                                </Col>

                                <Col md={6}>
                                    <MotionDiv direction="left" >
                                        <div className={styles.img}>
                                            <LazyImage
                                                src="/images/feature3.webp"
                                                alt="hello worlds eductors"
                                                width="100%"
                                            />
                                        </div>
                                    </MotionDiv>
                                </Col>
                            </Row>
                        </div>

                        {
                            window.innerWidth > 768 ? (
                                <div className={styles.feature}>
                                    <Row>
                                        <Col md={6}>
                                            <MotionDiv direction="right" >
                                                <div className={styles.img}>
                                                    <LazyImage
                                                        src="/images/feature4.webp"
                                                        alt="hello worlds eductors"
                                                        width="100%"
                                                    />
                                                </div>
                                            </MotionDiv>
                                        </Col>

                                        <Col md={6}>
                                            <MotionDiv direction="left" >
                                                <div className={styles.content}>
                                                    <h6>
                                                        Host contests
                                                    </h6>
                                                    <p>
                                                        No better way to up the club tempo than to host contests and collaborative projects. Use the club to frequently conduct these activities which can also lead to new friendships among kids.
                                                    </p>
                                                </div>
                                            </MotionDiv>
                                        </Col>


                                    </Row>
                                </div>
                            ) : (
                                <div className={styles.feature}>
                                    <Row>
                                        <Col md={6}>
                                            <MotionDiv direction="right" >
                                                <div className={styles.content}>
                                                    <h6>
                                                        Host contests
                                                    </h6>
                                                    <p>
                                                        No better way to up the club tempo than to host contests and collaborative projects. Use the club to frequently conduct these activities which can also lead to new friendships among kids.
                                                    </p>
                                                </div>
                                            </MotionDiv>
                                        </Col>

                                        <Col md={6}>
                                            <MotionDiv direction="left" >
                                                <div className={styles.img}>
                                                    <LazyImage
                                                        src="/images/feature4.webp"
                                                        alt="hello worlds eductors"
                                                        width="100%"
                                                    />
                                                </div>
                                            </MotionDiv>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }
                    </div>
                </Container>
            </section>

            <section className={styles.section4}>
                <Container fluid="lg">
                    <MotionDiv delay={0.1}>
                        <div className={styles.top}>
                            <TextAppearAnimation
                                text="How do you apply?"
                                className={styles.h4}
                            />
                            <TextAppearAnimation
                                text="Submit the application and tell us your unique value proposition. After that..."
                                className={styles.p}
                                center={true}
                            />
                            <MotionDiv delay={0.1}>
                                <LazyImage
                                    src="/images/eduSectionbg2.png"
                                    alt="hello worlds eductors"
                                    width="100%"
                                />
                            </MotionDiv>

                            <MotionDiv direction="up" delay={0.1}>
                                <button
                                    className="buttonGradiant"
                                    onClick={handleOpenFormModal}
                                >
                                    Apply Now
                                </button>
                            </MotionDiv>
                        </div>
                    </MotionDiv>

                    <div className={styles.mid}>
                        <MotionDiv direction="right" delay={0.1}>
                            <h4 className="textColorPrimary text-center">
                                <span className="textColorSecondary"> Why </span>HELLOWORLDS<span className="textColorSecondary">?</span>
                            </h4>
                        </MotionDiv>

                        <div className={styles.snapScollContainer}>
                            <div className={styles.wrapper}>
                                <TextAppearAnimation
                                    text="The Business Advantage"
                                    center={true}
                                    className={styles.h5}
                                />
                                <div className={styles.grid}>
                                    <div className={styles.card}>
                                        <LazyImage
                                            src="/images/card1.webp"
                                            alt="hello worlds eductors"
                                        />
                                        <div className={styles.caption}>
                                            <h6>
                                                No listing fee, no hidden charges, no marketing cost
                                            </h6>
                                            <p>
                                                That's right. We want you to focus only on inspiring, and we'll do the rest and get you the right audience
                                            </p>
                                        </div>
                                    </div>

                                    <div className={styles.card}>
                                        <LazyImage
                                            src="/images/card2.webp"
                                            alt="hello worlds eductors"
                                        />
                                        <div className={styles.caption}>
                                            <h6>
                                                Everything's flexible - batch size, cost, time, and more!
                                            </h6>
                                            <p>
                                                What you charge, when you wish to host, choosing club size, and a host of other properties, all under your control.
                                            </p>
                                        </div>
                                    </div>

                                    <div className={styles.card}>
                                        <LazyImage
                                            src="/images/card3.webp"
                                            alt="hello worlds eductors"
                                        />
                                        <div className={styles.caption}>
                                            <h6>
                                                Activity? Class? Mentorship? You decide.
                                            </h6>
                                            <p>
                                                We want you to comfortably share your expertise with the kids in any format you decide. All we ask for is for you to keep it practical, fun & appropriate.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.wrapper}>
                                <TextAppearAnimation
                                    text="The Platform Advantage"
                                    center={true}
                                    className={styles.h5}
                                />
                                <div className={styles.grid}>
                                    <div className={styles.card}>
                                        <LazyImage
                                            src="/images/card4.webp"
                                            alt="hello worlds eductors"
                                        />
                                        <div className={styles.caption}>
                                            <h6>
                                                No, seriously, you don't need any software
                                            </h6>
                                            <p>
                                                Juggling between Zoom, email schedulers, Whatsapp, payment gateways can be tough. Hence, we bring you an all-integrated platform.
                                            </p>
                                        </div>
                                    </div>

                                    <div className={styles.card}>
                                        <LazyImage
                                            src="/images/card5.webp"
                                            alt="hello worlds eductors"
                                        />
                                        <div className={styles.caption}>
                                            <h6>
                                                Communications via in-house 1:1 & group chats
                                            </h6>
                                            <p>

                                                Maintain your personal and teaching life separately, all in 1 communications tool integrated within the platform for 1:1 & group chats, content sharing etc.
                                            </p>
                                        </div>
                                    </div>

                                    <div className={styles.card} >
                                        <LazyImage
                                            src="/images/card6.webp"
                                            alt="hello worlds eductors"
                                        />
                                        <div className={styles.caption}>
                                            <h6>
                                                All inclusive teaching tools for every subject & experience
                                            </h6>
                                            <p>
                                                Whether you need a whiteboard, YouTube livestream, complex calculator, breakout rooms or polls, we provide it all.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className={styles.wrapper}>
                                <TextAppearAnimation
                                    text="The Long-Term Advantage"
                                    center={true}
                                    className={styles.h5}
                                />
                                <div className={styles.grid}>
                                    <div className={styles.card} >
                                        <LazyImage
                                            src="/images/card7.webp"
                                            alt="hello worlds eductors"
                                        />
                                        <div className={styles.caption}>
                                            <h6>
                                                Move your workshops offline
                                            </h6>
                                            <p>
                                                Soon, you can have kids visit you at a destination to drive your learning better! Best suited for enterprises.
                                            </p>
                                        </div>
                                    </div>

                                    <div className={styles.card}>
                                        <LazyImage
                                            src="/images/card8.webp"
                                            alt="hello worlds eductors"
                                        />
                                        <div className={styles.caption}>
                                            <h6>
                                                Manage your community in our in-house social media
                                            </h6>
                                            <p>
                                                Mhmm! We've built an in-house community ecosystem so that you can post updates, photos and videos, just like on Facebook/Instagram.
                                            </p>
                                        </div>
                                    </div>

                                    <div className={styles.card} >
                                        <LazyImage
                                            src="/images/card9.webp"
                                            alt="hello worlds eductors"
                                        />
                                        <div className={styles.caption}>
                                            <h6>
                                                Conduct QnA and discussions, and empower kids
                                            </h6>
                                            <p>
                                                Build your network by hosting discussions and empowering children about your industry. That's how true growth happens.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <MotionDiv delay={0.1}>
                        <div className={styles.bottom}>
                            <div className={styles.imgBox}>
                                <LazyImage
                                    src="/images/bottom.webp"
                                    alt="hello worlds eductors"
                                    width="100%"
                                />
                                <div className={styles.content}>
                                    <h6>
                                        Become a World Mentor
                                    </h6>
                                    <p>
                                        Showcase your unique teaching style, cause and skills, and develop a new way of life.
                                    </p>
                                    <button
                                        className="buttonGradiant"
                                        onClick={handleOpenFormModal}
                                    >
                                        Apply Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </MotionDiv>
                </Container>
            </section>

            <FormModal
                show={showFormMoal}
                handleClose={handleCloseFormModal}
                currentStep={currentStep}
                totalSteps={FORMSTEPS}
                handleNextStep={handleNextStep}
                handlePrevStep={handlePrevStep}
                handleSubmit={handleFormSubmit}
                loading={loading}
            >
                {renderForm()}
            </FormModal>

        </main>
    )
}

export default Educators